<template>
  <CCard>
    <CCardHeader class="d-flex align-items-center">
      <TMessage content="New contract" bold />
      <div class="ml-auto">
        <TButton
          content="Create contract"
          icon="cil-folder-plus"
          class="px-4"
          @click="create"
          :options="{ disabled: creating }"
          :messageOptions="{ bold: true }"
        />
      </div>
    </CCardHeader>
    <CCardBody>
      <CRow class="mb-2">
        <CCol col="12" lg="6">
          <TInputText
            label="Contract name"
            class="mb-3"
            placeholder="Contract name"
            @update:value="data.id = $event"
          />
        </CCol>
        <CCol col="12" lg="6">
          <SSelectCustomer
            :value.sync="data.customer_id"
            @update:value="data.customer_id = $event"
            label="Customer"
            class="mb-3"
          />
        </CCol>
        <CCol col="12" lg="6">
          <CRow>
            <CCol col="12" md="6">
              <TInputDateTime
                :value.sync="data.start_date"
                @update:value="data.start_date = $event"
                label="Start date"
                class="mb-3"
              />
            </CCol>
            <CCol col="12" md="6">
              <TInputDateTime
                :value.sync="data.end_date"
                @update:value="data.end_date = $event"
                label="End date"
                class="mb-3"
              />
            </CCol>
          </CRow>
          <CRow>
            <CCol col="12" md="6">
              <SSelectOrganization
                :value.sync="data.sales_organization_id"
                :prepend="[]"
                label="Purchasing company"
                class="mb-3"
              />
            </CCol>
            <CCol col="12" md="6">
              <SSelectOrganization
                :value.sync="data.transport_organization_id"
                :prepend="[]"
                label="Transportation company"
                class="mb-3"
              />
            </CCol>
          </CRow>
          <CRow>
            <CCol col="12" md="6">
              <SSelectCurrency
                label="Currency type of cost of goods"
                :value.sync="data.purchase_cost_currency_id"
                :prepend="[]"
                class="mb-3"
              />
            </CCol>
            <CCol col="12" md="6">
              <SSelectCurrency
                label="Currency type of service fee"
                :value.sync="data.service_fee_currency_id"
                :prepend="[]"
                class="mb-3"
              />
            </CCol>
          </CRow>
        </CCol>

        <CCol col="12" lg="6">
          <TInputMoney
            placeholder="Additional cost"
            label="Additional cost"
            class="mb-3"
            :currency="data.service_fee_currency_id"
            @update:value="data.additional_cost = $event"
          />
        </CCol>
      </CRow>
    </CCardBody>
  </CCard>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      data: {
        start_date: 0,
        end_date: 0,
        purchase_cost_currency_id: "JPY",
        service_fee_currency_id: "VND",
        sales_organization_id: "tomoni-jp",
        transport_organization_id: "tomoni-vn",
      },
      disabled: false,
      key: "add-item",
    };
  },
  watch: {
    customer() {
      this.shipment_info = null;
    },
  },
  computed: {
    ...mapGetters({
      creating: "order.contracts.creating",
    }),
  },
  methods: {
    create() {
      const dataClean = this.lodash.pickBy(this.data, this.lodash.identity);

      this.$store.dispatch("order.contracts.create", dataClean).then((data) => {
        this.$router.push({ path: `${data.id}` });
      });
    },
  },
};
</script>
